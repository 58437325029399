import { IonContent } from '@ionic/react'

const LoginContainer = () => {
    return (
        <IonContent>
            
        </IonContent>
    )
}

export default LoginContainer
